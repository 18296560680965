import React,{Component} from 'react'

import Header from "../components/Header";
import donate from "../imgs/donate.png"
import Footer from "../components/Footer";
import Translator from "../utilities/Translator";
import SectionContainer from "../components/SectionContainer";
import ServerController from "../utilities/ServerController";
import { PayPalButton } from "react-paypal-button-v2";
import Lottie from "lottie-react-web";
import animation from "../donate-success.json";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";


export default class DonatePage extends Component {
    constructor() {
        super();
         this.state = {donateMethod: "once",selectedYear:0,donateAmount:0.00,lang:localStorage.getItem('lang'),donateElemetns:null,onceTitle:'',debitTitle:'',title:'',amount:0,success:false,showModal:false};
    }

    componentDidMount() {
        try{
            var qs = require('qs');
            let search=this.props.location.search.substring(1,this.props.location.search.length);
            let obj=qs.parse(search,{ allowPrototypes: true });
            //alert(JSON.stringify(obj.type))
            if(obj.hasOwnProperty('lang') && (obj.lang=='ar' || obj.lang=='en')){
                let lang=obj.lang;
                if(lang=='ar' || lang=='en'){
                    if(this.state.lang!=lang){
                        window.location.reload();
                        localStorage.setItem('lang',lang)
                        Translator.handleLang(lang,this.state.context)
                        this.setState({lang:lang})
                    }
                }
            }
            if(obj.hasOwnProperty('type') && (obj.type=='once' || obj.type=='debit')){
                let type=obj.type;
                this.setState({donateMethod:type})
            }
            /* let lang=obj.lang;
            lang=lang.substring(lang.indexOf('=')+1,lang.length);
            if(lang=='ar' || lang=='en'){
                if(this.state.lang!=lang){
                    window.location.reload();
                    localStorage.setItem('lang',lang)
                    Translator.handleLang(lang,this.state.context)
                    this.setState({lang:lang})
                }
            }*/

        }catch (e) {
            //alert(JSON.stringify(e))
        }
        if(this.props.location.state!=null) {
            const {donateVal} = this.props.location.state
            this.setState({donateAmount:donateVal})

        }
        let controller=new ServerController();
        controller.getDonateElements(this.handleData,this)
    }
    handleData(data,context){
        context.setState({donateElemetns:data})
        //context.getDonateTitle();
        context.getDonateChecks();
    }
    getDonationMethod(){
        if(this.state.donateMethod === 'card'){
            var years=[];
            var months=[];
            var today = new Date()
            years.push(<option>{Translator.getString('Expiration Year',this.state.lang)}</option>)
            months.push(<option>{Translator.getString('Expiration Month',this.state.lang)}</option>)
            for(var i=today.getFullYear();i<today.getFullYear()+10;i++){
                years.push(<option>{i}</option>)
            }
            if(this.state.selectedYear==today.getFullYear()){
                var month=today.getMonth()+1;
                for(var i=month;i!=13;i++){
                    months.push(<option>{i}</option>)
                }
            }else{
                for(var i=1;i<=12;i++){
                    months.push(<option>{i}</option>)
                }
            }

            return(
                <div>
                    <br/>
                    <input type='text' className='volForms' placeholder={Translator.getString('Card Owner Name',this.state.lang)}></input>
                    <input type='text' className='volForms' placeholder={Translator.getString('Credit Card Number',this.state.lang)}></input>
                    <div>
                        <select className="form-control classCardExpSelect"  placeholder='year' onChange={event => this.setState({selectedYear:event.target.value})}>
                            {years}
                        </select>
                        <select className="form-control classCardExpSelect" id={this.state.lang=='ar'?'monthExpSelectAr':'monthExpSelect'}  placeholder='year'>
                            {months}
                        </select>
                    </div>
                    <input type='text' className='volForms' placeholder={Translator.getString('Security Digits (CVV)',this.state.lang)}></input>
                    <button id='submitBtn' type="submit" >{Translator.getString('Donate',this.state.lang)}</button>
                </div>

            );
        }
        if(this.state.donateMethod === 'paypal'){
            return(
                <div>
                    <br/>
                    <button id='submitBtn' type="submit" >Donate with PayPal</button>
                </div>
            );
        }
    }
    getDonateAmountInput(){
        if(this.state.donateAmount!=0){
            return(
                <input type='text' id='donateAmountInput' placeholder='0.00'  value={this.state.donateAmount}/>
            )
        }
        return(
            <input type='text' id='donateAmountInput' placeholder='0.00' />
        )

    }
    incDonateAmount(){
        let val=parseInt(this.state.donateAmount);
        val++;
        this.setState({donateAmount:val})
    }
    decDonateAmount(){
        if(this.state.donateAmount>0)
             this.setState({donateAmount:this.state.donateAmount-1})
    }
    getUrl(){
        if(this.state.donateMethod=="debit"){
            if(this.state.lang=='ar'){
                if(this.state.donateAmount!=0){
                    return 'https://secure.cardcom.solutions/e/bbJ?sum'+this.state.donateAmount;
                }

                    return 'https://secure.cardcom.solutions/e/bbJ';
            }
            if(this.state.donateAmount!=0){
                return "https://secure.cardcom.solutions/e/xYCb?sum="+this.state.donateAmount;
            }
            return "https://secure.cardcom.solutions/e/xYCb";
        }

        /*  oncee */
        if(this.state.lang=='ar'){
            if(this.state.donateAmount!=0){
                return "https://secure.cardcom.solutions/e/AkB?sum="+this.state.donateAmount;
            }
            return 'https://secure.cardcom.solutions/e/AkB/';
        }
        if(this.state.donateAmount!=0){
            return "https://secure.cardcom.solutions/e/Dlp?sum="+this.state.donateAmount;
        }
        return "https://secure.cardcom.solutions/e/Dlp/";
    }
    getDonateChecks(){
        if(this.state.donateElemetns==null) return '';
        let title='';
        for(let i=0;i<this.state.donateElemetns.length;i++){
            if( this.state.donateElemetns[i].name=='directdebit' ){
                title=this.state.lang=='ar'?this.state.donateElemetns[i].textAr:this.state.donateElemetns[i].text;
                this.setState({debitTitle:title})
            }
            if( this.state.donateElemetns[i].name=='donateonce' ){
                title=this.state.lang=='ar'?this.state.donateElemetns[i].textAr:this.state.donateElemetns[i].text;
                this.setState({onceTitle:title})
            }
        }


    }
    getDonateTitle(){
        if(this.state.donateElemetns==null) return '';
        let title='';
        for(let i=0;i<this.state.donateElemetns.length;i++){
            if(this.state.donateMethod=="debit" && this.state.donateElemetns[i].name=='directdebit' ){
                title=this.state.lang=='ar'?this.state.donateElemetns[i].textAr:this.state.donateElemetns[i].text;
                //this.setState({title:title})
                return title;
            }
            if(this.state.donateMethod=="once" && this.state.donateElemetns[i].name=='donateonce' ){
                title=this.state.lang=='ar'?this.state.donateElemetns[i].textAr:this.state.donateElemetns[i].text;
                //this.setState({title:title})
                return title;
            }
        }

        return title;
    }
    getDonateText(){
        if(this.state.donateElemetns==null) return '';
        let text='';
        for(let i=0;i<this.state.donateElemetns.length;i++){
            if(this.state.donateMethod=="debit" && this.state.donateElemetns[i].name=='directdebittext' ){
                text=this.state.lang=='ar'?this.state.donateElemetns[i].textAr:this.state.donateElemetns[i].text;
                //this.setState({title:title})
                return text;
            }
            if(this.state.donateMethod=="once" && this.state.donateElemetns[i].name=='donateoncetext' ){
                text=this.state.lang=='ar'?this.state.donateElemetns[i].textAr:this.state.donateElemetns[i].text;
                //this.setState({title:title})
                return text;
            }
        }

        return text;
    }
    render() {
        return (
            <div dir={this.state.lang=="ar"?"rtl":"ltr"}>
                <Header page={'donate'} context={this}></Header>
                <div id="pageContent">
                    <div id='subPageContent'>
                        <div class='row'>

                            <div className='col-xl-6 col-lg-6 col-sm-12 col-12' id='donateLeft'>
                                {
                                    /* <div hidden={this.state.donateMethod === 'debit'}>
                                    <SectionContainer page={"Donate"}/>
                                </div>
                                <div hidden={this.state.donateMethod === 'once'}>

                                </div>*/
                                }
                                <SectionContainer page={"Donate"}/>
                            </div>
                            <div  className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                                {
                                    /*this.state.donateMethod === 'debit'&&<div>
                                    <div id='Volform'>
                                    <div className='donateFormsCheck row'>
                                        <div className="form-check "
                                             className={this.state.lang == 'ar' ? 'myform-checkAr col-6' : 'myform-check col-6'}>
                                            <label className="form-check-label" htmlFor="radio1">
                                                <input type="radio" className="form-check-input" id="radio1" style={{marginTop:'11px'}}
                                                       value="once"
                                                       onChange={event => this.setState({donateMethod: event.target.value})}
                                                       checked={this.state.donateMethod === 'once'}
                                                />{this.state.onceTitle}</label>
                                        </div>
                                        <div className="form-check col-6"
                                             className={this.state.lang == 'ar' ? 'myform-checkAr col-6' : 'myform-check col-6'}>
                                            <label className="form-check-label " htmlFor="radio2">
                                                <input type="radio" className="form-check-input" id="radio2" style={{marginTop:'11px'}}
                                                       value="debit"
                                                       onChange={event => this.setState({donateMethod: event.target.value})}
                                                       checked={this.state.donateMethod === 'debit'}/>{this.state.debitTitle}
                                            </label>
                                        </div>

                                    </div>
                                </div>

                                    </div>*/
                                }

                                <div hidden={this.state.success}>
                                    <div id='Volform'>
                                        <div className='donateFormsCheck row'>
                                            <div className="form-check "
                                                 className={this.state.lang == 'ar' ? 'myform-checkAr col-6' : 'myform-check col-6'}>
                                                <label className="form-check-label" htmlFor="radio1">
                                                    <input type="radio" className="form-check-input" id="radio1" style={{marginTop:'11px'}}
                                                           value="once"
                                                           onChange={event => this.setState({donateMethod: event.target.value})}
                                                           checked={this.state.donateMethod === 'once'}
                                                    />{this.state.onceTitle}</label>
                                            </div>
                                            <div className="form-check col-6"
                                                 className={this.state.lang == 'ar' ? 'myform-checkAr col-6' : 'myform-check col-6'}>
                                                <label  className="form-check-label " htmlFor="radio2">
                                                    <input type="radio" className="form-check-input" id="radio2" style={{marginTop:'11px'}}
                                                           value="debit"
                                                           onChange={event => this.setState({donateMethod: event.target.value})}
                                                           checked={this.state.donateMethod === 'debit'}/>{this.state.debitTitle}
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                    <h3 id='donateMethodText'>{this.getDonateTitle()}</h3>
                                    <h6 id='donateMethodText'>{this.getDonateText()}</h6>
                                    <div hidden={this.state.donateMethod === 'debit'}>
                                        <div className={'donateInputWrap'} style={{width:'100%',height:40}}>

                                            <input className={'donateAmountInput'} type={'number'} onChange={event => this.setState({amount:event.target.value>-1?event.target.value:0})} placeholder={Translator.getString('DONATE_AMOUNT',this.state.lang)}  />

                                            {
                                                /*
                                                 <span onClick={()=>{
                                                this.setState({amount:this.state.amount+1})
                                            }} className={'incButton'}>+</span>
                                                */
                                            }
                                        </div>
                                        <span  className={'donateAmountLabel'}>{this.state.amount+""}&#8362;</span>
                                        <div style={{marginTop:15}}>
                                            <PayPalButton
                                                //currency="ILS"

                                                amount={this.state.amount}
                                                shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                onSuccess={(details, data) => {
                                                    this.setState({success:true})
                                                }}
                                                options={{

                                                    locale:this.state.lang=='ar'?'he_IL':'en_IL',
                                                    currency:"ILS",
                                                    clientId:"ATQkvKHDsuDSTPniwySaZ39gRhMRXtMK1DtpFMl-oBuiqgvYc-cp25p6OK809f_x3x_Ri-vJ6Hn8_Uzl",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div hidden={this.state.donateMethod === 'once'}>
                                        <div hidden={this.state.lang != 'ar'}>
                                            <a href={'https://www.paypal.com/donate?hosted_button_id=VYQXFZBBCNY2E'}>
                                                <button style={{width:'100%',height:45,background:'#4c4c4c',marginTop:30,borderRadius:5,border:"unset"}}>
                                                    <span style={{color:'#fff',fontWeight:600}}>اضغط هنا للتبرع الشهري عبر paypal</span>
                                                </button>
                                            </a>
                                            <a href={'https://meshulam.co.il/quick_payment?b=e0c338e8b41aad13807f459badf6d9bc'}>
                                                <button style={{width:'100%',height:45,background:'#4c4c4c',marginTop:15,borderRadius:5,border:"unset"}}>
                                                    <span style={{color:'#fff',fontWeight:600}}>اضغط هنا للتبرع الشهري عبر بطاقة الإعتماد</span>
                                                </button>
                                            </a>
                                            <button onClick={()=>{this.setState({showModal:true})}} style={{width:'100%',height:45,background:'#4c4c4c',marginTop:15,borderRadius:5,border:"unset"}}>
                                                <span style={{color:'#fff',fontWeight:600}}>تبرع شهري عبر التحويل البنكي</span>
                                            </button>
                                            <Modal show={this.state.showModal  } onHide={()=>{this.setState({showModal:false})}} id={'articalModal'} dir={this.state.lang=="ar"?"rtl":"ltr"} >
                                                <Modal.Header closeButton>
                                                    <Modal.Title></Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                   <div style={{width:'70%',margin:"auto"}}>
                                                       <ReactPlayer  controls={true} style={{maxWidth:'100%',margin:"auto",marginBottom:30}} url='https://seedsofhumanity.org/EXPORT15seeds.mp4' />
                                                        <span style={{display:"block",width:'100%',textAlign:'center',fontSize:16,fontWeight:600}}>شاهد الفيديو للشرح عن كيفية إنشاء كفالة شهرية من حسابك البنكي باستعمال تطبيق البنك الموجود بهاتفك بعملية بسيطة جدًا
وبعدها قم بمراسلتنا عبر الواتساب وارسال صورة شاشة للتبرع الشهري حتى نقوم بتسجيل التفاصيل</span>
                                                       <span style={{display:"block",width:'100%',textAlign:'center',fontSize:16,fontWeight:600}}>تفاصيل حساب بذور البنكي:</span>
                                                       <span style={{display:"block",width:'100%',textAlign:'center'}}>بنك: مزراحي طفحوت מזרחי טפחות (20)</span>
                                                       <span style={{display:"block",width:'100%',textAlign:'center'}}>رقم الفرع: 549</span>
                                                       <span style={{display:"block",width:'100%',textAlign:'center'}}>رقم الحساب: 101074</span>
                                                       <span style={{display:"block",width:'100%',textAlign:'center'}}>שם מוטב: ניצנים</span>
                                                       <span style={{display:"block",width:'100%',textAlign:'center'}}>מהות העברה: תרומה تبرع</span>
                                                       <span style={{display:"block",width:'100%',textAlign:'center',fontSize:16,fontWeight:600,marginTop:30}}>للتبرع الشهري عبر بطاقة الإعتماد لمن يملك حساب Paypal
اضغط على الرابط وقم بتحديد مبلغ التبرع (مع الانتباه لاضافة صفرين بعد الفاصلة العشرية) وقم بتحديد إمكانية التحويل الشهري وبعدها ادخل لحسابك البايبال (شرح مبسط بالقسم الثاني من الفيديو)</span>

                                                       <span style={{display:"block",width:'100%',textAlign:'center',fontSize:16,fontWeight:600,marginTop:20}}>لأي مساعدة او سؤال او استفسار بامكانكم مراستلنا عبر الهاتف او عبر الواتساب على رقم: 048669955 (يوجد واتساب على نفس الرقم)

بارك الله بكم</span>
                                                   </div>


                                                </Modal.Body>

                                            </Modal>
                                            {
                                                /*


                                                 */
                                            }
                                        </div>
                                        <div hidden={this.state.lang == 'ar'}>

                                            <a href={'https://www.paypal.com/donate?hosted_button_id=VYQXFZBBCNY2E'}>
                                                <button style={{width:'100%',height:40,background:'#4c4c4c',marginTop:30,borderRadius:5,border:"unset"}}>
                                                    <span style={{color:'#fff',fontWeight:600}}>paypal monthly donation</span>
                                                </button>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <div hidden={!this.state.success}>
                                    {
                                        this.state.success&&<Lottie
                                            width={350}
                                            height={350}
                                            options={{
                                                animationData: animation,
                                                loop: false,

                                            }}/>
                                    }
                                    <p className={'donate_thanks'}>
                                        {Translator.getString('DONATE_THANKS',this.state.lang)}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer lang={this.state.lang}/>
            </div>
        )
    }
}
/*
<div id='Volform'>

                                    <div id='donateAmountDiv'>
                                        <h6>{Translator.getString('DONATE AMOUNT',this.state.lang)}</h6>
                                        <button onClick={()=>this.incDonateAmount()}>+</button>
                                        {this.getDonateAmountInput()}
                                        <button onClick={()=>this.decDonateAmount()}>-</button>
                                    </div>
                                    <br/>
                                    <h6>{Translator.getString('RECEIPT DETAILS',this.state.lang)}</h6>
                                    <input type='text'  class='volForms' placeholder={Translator.getString("First Name",this.state.lang)}  ></input>
                                    <input type='text' className='volForms' placeholder={Translator.getString("last Name",this.state.lang)}></input>
                                    <input type='text' className='volForms' placeholder={Translator.getString('Phone number',this.state.lang)}></input>
                                    <input type='text' className='volForms' placeholder={Translator.getString('Email',this.state.lang)}></input>
                                    <br/>
                                    <h6>{Translator.getString('DONATION METHOD',this.state.lang)}</h6>
                                    <div className='donateFormsCheck'>
                                        <div className="form-check" class={this.state.lang=='ar'?'myform-checkAr':'myform-check'}>
                                            <label className="form-check-label" htmlFor="radio1">
                                                <input type="radio" className="form-check-input" id="radio1"
                                                       value="card"  onChange={event=>this.setState({donateMethod:event.target.value})} checked={this.state.donateMethod === 'card'}
                                                />{Translator.getString('Credit Card',this.state.lang)}</label>
                                        </div>
                                        <div className="form-check" class={this.state.lang=='ar'?'myform-checkAr':'myform-check'}>
                                            <label className="form-check-label " htmlFor="radio2">
                                                <input type="radio" className="form-check-input" id="radio2"
                                                       value="paypal" onChange={event=>this.setState({donateMethod:event.target.value})} checked={this.state.donateMethod === 'paypal'}/>{Translator.getString('PayPal',this.state.lang)}</label>
                                        </div>
                                        <div className="form-check" class={this.state.lang=='ar'?'myform-checkAr':'myform-check'}>
                                            <label className="form-check-label " htmlFor="radio2">
                                                <input type="radio" className="form-check-input" id="radio2"
                                                      onChange={event=>this.setState({donateMethod:event.target.value})} checked={this.state.donateMethod === 'debit'}
                                                       value="debit"/>{Translator.getString('Direct debit',this.state.lang)}</label>
                                        </div>
                                        <div className="form-check" class={this.state.lang=='ar'?'myform-checkAr':'myform-check'}>
                                            <label className="form-check-label " htmlFor="radio2">
                                                <input type="radio" className="form-check-input" id="radio2"
                                                       onChange={event=>this.setState({donateMethod:event.target.value})} checked={this.state.donateMethod === 'cash'}
                                                       value="cash"/>{Translator.getString('Cash',this.state.lang)}</label>
                                        </div>
                                    </div>
                                    {this.getDonationMethod()}
                                </div>
 */
