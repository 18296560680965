import React,{Component} from 'react'
import '../App.css';
import logo from '../logo.png'
import newlogo from '../imgs/newLog.png'
import newlogoSmall from '../imgs/newLogoSmall.png'
import logoSmall from '../logoSmall.png'
import facebookIcon from '../imgs/Facebook.png'
import facebookBlueIcon from '../imgs/FacebookBlue.png'
import twitterIcon from '../imgs/Twitter.png'
import twitterBlueIcon from '../imgs/TwitterBlue.png'
import instaIcon from '../imgs/InstagramGreen.png'
import instaBlue from '../imgs/InstagramBlue.png'

import youtubeIcon from '../imgs/youtubeGreen.png'
import youtubeBlue from '../imgs/youtubeBlue.png'
import lineImg from '../imgs/line.png'
import {Link} from "react-router-dom";
import menuIcon from '../imgs/menuIcon.png'
import Translator from "../utilities/Translator";
export default class Header extends Component {


    constructor() {
        super();
        this.state = {fbhover: false,twhover:false,inhover:false,youtubehover:false,scrolling:false,selected:'home',lang:localStorage.getItem('lang'),context:null,arSize:'17px'};
    }
    componentDidMount() {
        this.setState({selected:this.props.page,context:this.props.context})
        window.addEventListener('scroll', ()=>this.handleScroll());

    }
    handleScroll() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            //document.getElementById("header").style.fontSize = "30px";
            //alert("scrolling")
            this.setState({scrolling:true})
        } else {
            this.setState({scrolling:false})
            //document.getElementById("header").style.fontSize = "90px";
        }
    }
    handleLangBtn(lang){
        window.location.reload();

        Translator.handleLang(lang,this.state.context)
        this.setState({lang:lang})
    }
    getDropClass(){
        var isChrome = false;
        return this.state.lang=='ar'&& isChrome?'dropdown-content headItemAr':'dropdown-content'
    }
    render() {
        return (


            <nav className="navbar navbar-expand-lg"  id={!this.state.scrolling?"headerWrap":"headerWrapScroll"} >
                <Link to="/"> <img id='seedsLogo'   src={!this.state.scrolling?newlogo:newlogoSmall}/></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#collapsibleNavbar">
                    <img  src={menuIcon}/>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar" style={{backgroundColor:'#e7e7e7'}}>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/"> <button  className={this.state.selected=='home'?"dropbtn dropbtnSelected":"dropbtn"} style={this.state.lang=='ar'?{fontSize:this.state.arSize}:{}}>{Translator.getString('HOME',this.state.lang)}</button></Link>
                        </li>
                        <li className="nav-item">
                            <div className="dropdown" >
                                <button className={this.state.selected=='whoweare'?"dropbtn dropbtnSelected":"dropbtn"} style={this.state.lang=='ar'?{fontSize:this.state.arSize}:{}}>{Translator.getString('WHO WE ARE',this.state.lang)}</button>
                                <div id='whoweareDrop' className={this.getDropClass()}>
                                    {/*<Link className={'headerDropLink'} to="/missionsandvision">{Translator.getString('MISSIONS & VISION',this.state.lang)}</Link>*/}
                                    <Link className={'headerDropLink'}  to="/ourstory">{Translator.getString('OUR STORY',this.state.lang)}</Link>
                                    <Link className={'headerDropLink'}  to="/ourteam">{Translator.getString('OUR TEAM',this.state.lang)}</Link>
                                    <Link className={'headerDropLink'}  to="/ourvolunteers">{Translator.getString('OUR VOLUNTEERS',this.state.lang)}</Link>
                                    <Link  className={'headerDropLink'} to="/ourpartners">{Translator.getString('OUR PARTNERS',this.state.lang)}</Link>
                                    <Link  className={'headerDropLink'}  to="/boardmembers">{Translator.getString('BOARD MEMBERS',this.state.lang)}</Link>

                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="dropdown" >
                                <button className={this.state.selected=='whatwedo'?"dropbtn dropbtnSelected":"dropbtn"} style={this.state.lang=='ar'?{fontSize:this.state.arSize}:{}}>{Translator.getString('WHAT WE DO',this.state.lang)}</button>
                                <div className={this.getDropClass()} id='whatwedoDrop'>
                                    <Link to="/wherewework">{Translator.getString('WHERE WE WORK',this.state.lang)}</Link>
                                    <Link to="/programs">{Translator.getString('PROGRAMS',this.state.lang)}</Link>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="dropdown" >
                                <button className={this.state.selected=='news'?"dropbtn dropbtnSelected":"dropbtn"} style={this.state.lang=='ar'?{fontSize:this.state.arSize}:{}}>{Translator.getString('NEWS & EVENTS',this.state.lang)}</button>
                                <div className={this.getDropClass()} id='newsDrop'>
                                    <Link to="/media">{Translator.getString('GALLERY',this.state.lang)}</Link>
                                    <Link to="/conferences">{Translator.getString('CONFERENCES',this.state.lang)}</Link>
                                    <Link to="/mediaAndarticles">{Translator.getString('MEDIA & ARTICLES',this.state.lang)}</Link>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact"><button className={this.state.selected=='contact'?"dropbtnSelected":""} style={this.state.lang=='ar'?{fontSize:this.state.arSize}:{}} id='contactBtn'>{Translator.getString('CONTACT',this.state.lang)}</button></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/donate"><button  className={this.state.selected=='donate'?"dropbtnSelected":""} style={this.state.lang=='ar'?{fontSize:this.state.arSize,paddingLeft:'25px',paddingRight:'25px'}:{}}id='donateBtn'>{Translator.getString('DONATE',this.state.lang)}</button></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/volunteer"><button className={this.state.selected=='volunteer'?"dropbtnSelected":""} style={this.state.lang=='ar'?{fontSize:this.state.arSize,paddingLeft:'25px',paddingRight:'25px'}:{}} id='volBtn'>{Translator.getString('VOLUNTEER',this.state.lang)}</button></Link>
                        </li>
                        <li className="nav-item">
                            <div id='socialIcons'>
                                <div className={"socialIcon"}>
                                    <a href={'https://www.facebook.com/seedsofhumanity'} target={'_blank'}><img class='socialIconImg' src={!this.state.fbhover ? facebookIcon : facebookBlueIcon}
                                         onMouseOver={() => this.setState({fbhover: true})}
                                             onMouseOut={() => this.setState({fbhover: false})}/></a>
                                </div>
                                <div className={"socialIcon"} id={this.state.lang=='ar'?"twiconAr":"twicon"}>
                                    <a href={'https://twitter.com/Seeds_Humanity'} target={'_blank'}><img class='socialIconImg' src={!this.state.twhover ? twitterIcon : twitterBlueIcon}
                                         onMouseOver={() => this.setState({twhover: true})}
                                            onMouseOut={() => this.setState({twhover: false})}/></a>
                                </div>
                                <div className={"socialIcon"} id={this.state.lang=='ar'?"twiconAr":"twicon"}>
                                    <a href={'https://www.instagram.com/seeds_of_humanity/'} target={'_blank'}><img
                                        className='socialIconImg'
                                        src={!this.state.inhover ? instaIcon : instaBlue}
                                        onMouseOver={() => this.setState({inhover: true})}
                                        onMouseOut={() => this.setState({inhover: false})}/></a>
                                </div>
                                <div className={"socialIcon"} id={this.state.lang=='ar'?"twiconAr":"twicon"}>
                                    <a href={'https://www.youtube.com/channel/UCRwafWzlv69ZREDLonz53uw'} target={'_blank'}><img
                                        className='socialIconImg'
                                        src={!this.state.youtubehover ? youtubeIcon : youtubeBlue}
                                        onMouseOver={() => this.setState({youtubehover: true})}
                                        onMouseOut={() => this.setState({youtubehover: false})}/></a>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div id='langSelection' style={this.state.lang=='ar'?{height:'60px'}:{}}>
                                <Link class={this.state.lang=="ar"?'langBtnSelected':'langBtn'} style={this.state.lang=='ar'?{fontSize:this.state.arSize}:{}} onClick={()=>this.handleLangBtn("ar")} to='/'>العربية</Link>
                                <img src={lineImg} id='splitLine'/>
                                <Link class={this.state.lang=="en"?'langBtnSelected':'langBtn'}style={this.state.lang=='ar'?{fontSize:this.state.arSize}:{}}  onClick={()=>this.handleLangBtn("en")}>ENGLISH</Link>
                            </div>
                        </li>
                    </ul>
                </div>

            </nav>

        )
    }
}

